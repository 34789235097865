// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-single-article-js": () => import("./../src/templates/single_article.js" /* webpackChunkName: "component---src-templates-single-article-js" */),
  "component---src-templates-fixed-page-js": () => import("./../src/templates/fixed_page.js" /* webpackChunkName: "component---src-templates-fixed-page-js" */),
  "component---src-templates-blog-page-js": () => import("./../src/templates/blog_page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

